
$gray1: #282a30
$gray2: #404149
$gray3: #51525c
$gray4: #868686
$gray5: #cccccc

$blue1: #5b8af7

$red1: #e25750

$green1: #61ab51

$screen1: 479px
$screen2: 480px
