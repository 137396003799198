
.PreviewWidget
    position: relative
    width: 100%
    display: flex
    flex-direction: column
    align-content: flex-start
    justify-content: flex-start
    row-gap: 1rem

.PreviewArea
    position: relative
    display: block
    font-size: 1.1rem
    line-height: 1.1rem
    min-height: 3.3rem
    white-space: pre-wrap
    hyphens: auto
    box-sizing: border-box
    width: 100%
    padding: 8px
    outline: 2px solid green

.VarList
    position: relative
    display: flex
    flex-direction: column
    // align-items: flex-start
    // justify-content: center
    row-gap: 0.5rem
    width: 100%

    & > li
        display: block
        width: 100%
        & > label
            position: relative
            height: 34px
            line-height: 30px
            font-size: 1rem
            display: flex
            flex-direction: row
            box-sizing: border-box
            padding: 2px 2px
            border: 1px solid gray
            border-radius: 6px
            & > span
                position: relative
                display: block
                position: relative
                &:after
                    position: relative
                    white-space: pre-wrap
                    content: " :  "
                    font-weight: 700
                    color: lime
            & > input
                display: block
                position: relative
                box-sizing: border-box

