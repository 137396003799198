@use "./assets/sass/vars.sass" as vars

$padding: 10px

@mixin centering
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center

.wrapper
    position: relative
    height: 100%
    width: 100%
    +centering
    @supports (min-height: 100dvh)
        min-height: 100dvh
    @supports not (min-height: 100dvh)
        min-height: 100wh

.App
    background-color: vars.$gray1
    border-radius: 12px
    outline: 3px solid cyan
    position: relative
    min-height: 820px
    height: 100%
    padding: $padding
    column-gap: 30px
    display: block
    width: 100%
    max-width: 1100px
    & h1
        display: block
        position: relative
        font-size: 1.5rem
        height: 2.5rem
        line-height: 2.5rem
        text-align: center
        margin-bottom: 0.5rem
        @media (max-width: vars.$screen1)
            font-size: 1.2rem
            height: 1.2rem
            line-height: 1.2rem
            font-weight: 700

@mixin visibility_switch($status)
    visibility: if($status == "off", hidden, visible)
    pointer-events: if($status == "off", none, all)
    opacity: if($status == "off", 0, 1)

.Modal
    display: block
    position: absolute
    width: calc(100% - #{$padding * 2})
    height: auto
    transition: 0.8s ease
    +visibility_switch("off")

.Modal--on
    +visibility_switch("on")
    background-color: vars.$gray1
    z-index: 999

.ButtonOpenModal
    display: block
    position: absolute
    // margin: 0 auto
    transform: translate(-50%, -50%)
    left: 50%
    top: 50%
    height: 36px
    line-height: 36px
    box-sizing: border-box
    border: 1px solid rgb(gold, 0.3)
    border-radius: 6px
    transition: 0.3s ease
    text-align: center
    padding: 0px 1rem
    cursor: pointer
    &:hover
        border: 1px solid gold
    &--off
        pointer-events: none
        display: none


