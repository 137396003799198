@use "../../assets/sass/vars.sass" as vars

.Container
    display: flex
    width: 100%
    height: 100%
    flex-direction: column
    align-items: center
    user-select: none
    // justify-content: center
    & textarea
        user-select: auto

.EditorArea
    --editor-min-height: 400px
    display: block
    position: relative
    height: 100%
    min-height: var(--editor-min-height, 400px)

.VarNames
    display: flex
    flex-direction: row
    column-gap: 8px
    margin-bottom: 1rem
    & li
        display: block
        box-sizing: border-box
        font-size: 1rem
        height: 2rem
        line-height: 2rem
        border: 1px solid #8a8a8a
        border-radius: 0.5rem
        padding: 0px 0.5rem
        transition: 0.3s ease
        cursor: pointer
        &:hover
            border: 1px solid #a5a5a5
            box-shadow: inset 0px 0px 3px #a5a5a5
        & span
            display: block
            position: relative
        & span:before
            content: "{ "
        & span:after
            content: " }"
        & span:before,span:after
            color: yellow

.IfThenElse
    display: block
    margin-bottom: 1rem
    display: block
    font-size: 1rem
    height: 2rem
    line-height: 2rem
    box-sizing: border-box
    border: 1px solid #5f8a61
    border-radius: 0.5rem
    padding: 0px 0.5rem
    cursor: pointer
    transition: 0.3s ease
    &:hover
        border: 1px solid #51c957
        box-shadow: inset 0px 0px 3px #51c957

.SplittingTextArea
    display: block

.Buttons
    margin-top: 1rem
    display: flex
    flex-direction: row
    gap: 0.5rem
    flex-wrap: wrap


.Button
    display: block
    position: relative
    // width: 100px
    padding: 0px 1rem
    height: 40px
    line-height: 36px
    box-sizing: border-box
    border: 1px solid rgb(gold, 0.3)
    cursor: pointer
    border-radius: 8px
    text-align: center
    transition: 0.3s ease
    &:hover
        border: 1px solid gold
    & > span
        display: block
        line-height: 34px
        height: 34px

@mixin visibility_switch($status)
    visibility: if($status == "off", hidden, visible)
    pointer-events: if($status == "off", hidden, all)
    opacity: if($status == "off", 0, 1)

.Modal
    display: block
    position: absolute
    width: 100%
    min-height: 100%
    transition: 0.8s ease
    +visibility_switch("off")

.Modal--on
    +visibility_switch("on")
    background-color: vars.$gray1
    z-index: 999

.Button-Close
    display: block
    position: relative
    margin: 0 auto
    margin-top: 0.5rem
    height: 40px
    width: fit-content
    padding: 2px 22px
    line-height: 34px
    box-sizing: border-box
    border: 1px solid cyan
    border-radius: 6px
    cursor: pointer